import React from 'react'
import { Col, Row } from 'react-bootstrap'

const mainPage = (
  <Col>
    <h1 className="App-title py-3">Osto- ja Myyntiliike Oivallus</h1>
    <div>
      <p>
        Meiltä Oivalluksesta löydät,
      </p>
      <p>
        lasitavaraa, Arabiaa, valaisimia, huonekaluja, ym. keräily- ja käyttötavaraa. Vuokraamme myös muuttolaatikoita, sekä ostamme muutto- ja kuolinpesiä.
      </p>
      <p>
        Pidämme kesäkirppistä Luhangassa 16.6.-31.8. osoitteessa Mämminiementie 9, Luhanka. Poikkea paikalle tai kysele mitä kaikkea meiltä löytyy.
      </p>
      <p>
        Pääliike Riihimäellä auki sovitusti, otathan yhteyttä.
      </p>
    </div>
  </Col>
)

const contact = (
  <Col>
    <h1 className="App-title py-3">Yhteystiedot</h1>
    <Row>
      <Col xl={3} className="text-left">
        <div>Kontiotie 2</div>
        <div>1120 Riihimäki</div>
        <div>oivallus@elisanet.fi</div>
        <div><b>Marjo</b> 0408461014</div>
        <div><b>Seppo</b> 0407534138</div>
      </Col>
      <Col className="d-none d-xl-block">
        <iframe
          title="Location"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3899.455724896866!2d24.77975904643735!3d60.746413805309324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1c8c64f7c9ada50!2sOsto-%20Ja%20Myyntiliike%20Oivallus%20avoin%20yhti%C3%B6!5e0!3m2!1sfi!2sfi!4v1610637898795!5m2!1sfi!2sfi"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: "0" }}
          allowFullScreen={false}
          aria-hidden={false}
          tabIndex={0}>
        </iframe>
      </Col>
    </Row>
  </Col>
)

const boxes = (
  <Col>
    <h1 className="App-title py-3">Muuttolaatikot</h1>
    <div>
      <p>
        Jolamuuttolaatikoita on hyvä käyttää muutoissa, varastoinneissa ja missä tahansa tavaroiden siirtämisessä.
        Esimerkiksi vesivahingon sattuessa on näihin laatikoihin helppoa ja nopeaa pakata tavaroita.
      </p>
      <p>
        Muuttolaatikot ovat 52 litran vetoisia ja niiden pohjakoko on 30x40x60cm.
      </p>
      <p>
        Saat aina puhtaat laatikot, sillä kaikki laatikot puhdistetaan vuokrauksen jälkeen.
      </p>
      <p>
        Farmariautoon muovisia sisäkkäin meneviä muuttolaatikoita sopii noin 30 kpl, jos autossa saa takapenkit käännettyä. Normaaliin henkilöautoon muttolaatikoita sopii noin 20kpl.
      </p>
      <p>
        Lisätietoja voit kysyä meiltä puhelimitse tai vaikkapa sähköpostitse.
      </p>
    </div>
  </Col>
)

export { mainPage, contact, boxes }
