import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Col, Container, Row, Navbar, Nav } from 'react-bootstrap'
import { paths } from './paths'
import { mainPage, contact, boxes } from './layouts/layouts'
import { ImagesLayout } from './layouts/ImagesLayout'

import './App.css';

const footerContent = (
  <>
    <Col>
      <h5 className="App-footer-header text-uppercase">Yhteystiedot</h5>
      <div>oivallus@elisanet.fi</div>
      <div>Kontiontie 2</div>
      <div>11120 Riihimäki</div>
    </Col>
    <Col>
      <h5 className="App-footer-header text-uppercase">Aukioloajat</h5>
      <div>Ti-Pe 10.00-18.00</div>
      <div>La 10.00-15.00</div>
    </Col>
  </>
)

function App() {

  const footerRef = useRef<HTMLDivElement>(null)
  const navBarRef = useRef<HTMLDivElement>(null)

  const [footerHeight, setFooterHeight] = useState(0)
  const [navBarHeight, setNavBarHeight] = useState(0)

  const calculateContentHeight = () => {
    if (navBarRef.current) {
      setNavBarHeight(navBarRef.current.offsetHeight)
    }

    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight)
    }
  }

  useEffect(() => {
    calculateContentHeight()
  }, [navBarHeight, footerHeight])

  const onResize = () => {
    calculateContentHeight()
  }

  window.addEventListener('resize', onResize)

  return (
    <>
      <Navbar ref={navBarRef} collapseOnSelect expand="md" bg="dark" variant="dark" style={{ zIndex: 1 }}>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Etusivu</Nav.Link>
            <Nav.Link href={paths.contact}>Yhteystiedot</Nav.Link>
            <Nav.Link href={paths.boxes}>Muuttolaatikot</Nav.Link>
            <Nav.Link href={paths.images}>Kuvat</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar >
      <Container fluid className="App">
        <div className="App-header-container" style={{ height: `calc(100vh - ${footerHeight}px - ${navBarHeight}px)`, top: `${navBarHeight}px` }}>
          <header className="App-header p-5 m-3">
            <Router>
              <Switch>
                <Route path={paths.contact}>
                  {contact}
                </Route>
                <Route path={paths.boxes}>
                  {boxes}
                </Route>
                <Route path={paths.images}>
                  <ImagesLayout />
                </Route>
                <Route exact path="/">
                  {mainPage}
                </Route>
                <Route render={() => <Redirect to={{ pathname: "/" }} />} />
              </Switch>
            </Router>
          </header>
        </div>
        <Row ref={footerRef} className="App-footer py-3 px-5 text-left">
          {footerContent}
        </Row>
      </Container>
    </>
  );
}

export default App;
