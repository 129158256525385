import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Modal, Button } from 'react-bootstrap'

import image1 from '../assets/images/image_1.jpg'
import image2 from '../assets/images/image_2.jpg'
import image3 from '../assets/images/image_3.jpg'
import image4 from '../assets/images/image_4.jpg'
import image5 from '../assets/images/image_5.jpg'
import image6 from '../assets/images/image_6.jpg'
import image7 from '../assets/images/image_7.jpg'
import image8 from '../assets/images/image_8.jpg'
import image9 from '../assets/images/image_9.jpg'
import image10 from '../assets/images/image_10.jpg'


export const ImagesLayout = () => {

  const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10]

  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>(image1)

  const handleClose = () => setShow(false);

  const handleShow = (img: any) => {
    setSelectedImage(img)
    setShow(true);
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="opened-object" className="d-block m-auto" style={{ maxHeight: "500px", maxWidth: "100%" }}></img>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sulje
        </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        {
          images.map((img, index) => {
            return (
              <Col
                xs={12}
                sm={6}
                lg={4}
                key={index}
                className="my-3"
              >
                <div className="App-image-overlay" onClick={() => handleShow(img)}>
                  <div>Avaa</div>
                </div>
                <img src={img} alt="object" height="200px" width="100%" style={{ objectFit: "cover" }} />
              </Col>
            )
          })
        }
      </Row>
    </>
  )
}
